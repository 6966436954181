import logo1 from "../images/1.svg";
import logo2 from "../images/2.svg";
import logo3 from "../images/3.svg";
const Inr = (props) => {
  return (
    <div className="payoutmodes">
      <div className="cards">
        <div className="payouttitle">
          <img src={logo1} className="logo" alt="UPI/IMPS" />
        </div>
        <div className="dedx">
          <div title="reasonofdeduction">Base Amount:</div>

          <div title="amountdeducted">INR {props.base}</div>
        </div>
        <div className="dedx">
          <div title="reasonofdeduction">
            Platform Fees ({props.platformupi}):
          </div>

          <div title="amountdeducted">- INR {props.platformupicalc}</div>
        </div>

        <div className="dedx">
          <div title="reasonofdeduction">GST ({props.gst}):</div>

          <div title="amountdeducted">- INR {props.upigst}</div>
        </div>
        <div className="dedx">
          <div title="reasonofdeduction">Transfer Fee:</div>

          <div title="amountdeducted">- INR {props.tnsfee}</div>
        </div>
        <div className="dedx blue">
          <div title="reasonofdeduction"></div>

          <div title="amountdeducted">INR {props.upitotal}</div>
        </div>
      </div>
      <div className="cards">
        <div className="payouttitle">
          <img src={logo2} className="logo" alt="Paytm wallet" />
        </div>
        <div className="dedx">
          <div title="reasonofdeduction">Base Amount:</div>

          <div title="amountdeducted">INR {props.base}</div>
        </div>
        <div className="dedx">
          <div title="reasonofdeduction">
            Platform Fees ({props.platformpaytm}):
          </div>

          <div title="amountdeducted">- INR {props.platformpaytmcalc}</div>
        </div>

        <div className="dedx">
          <div title="reasonofdeduction">GST ({props.gst}):</div>

          <div title="amountdeducted">- INR {props.paytmgst}</div>
        </div>
        <div className="dedx">
          <div title="reasonofdeduction">Transfer Fee:</div>

          <div title="amountdeducted">- INR {props.tnsfee}</div>
        </div>
        <div className="dedx blue">
          <div title="reasonofdeduction"></div>

          <div title="amountdeducted">INR {props.paytmtotal}</div>
        </div>
      </div>
      <div className="cards">
        <div className="payouttitle">
          <img src={logo3} className="logo" alt="Amazon wallet" />
        </div>
        <div className="dedx">
          <div title="reasonofdeduction">Base Amount:</div>

          <div title="amountdeducted">INR {props.base}</div>
        </div>
        <div className="dedx">
          <div title="reasonofdeduction">
            Platform Fees ({props.platformamazon}):
          </div>

          <div title="amountdeducted">- INR {props.platformamazoncalc}</div>
        </div>

        <div className="dedx">
          <div title="reasonofdeduction">GST ({props.gst}):</div>

          <div title="amountdeducted">- INR {props.amazongst}</div>
        </div>
        <div className="dedx">
          <div title="reasonofdeduction">Transfer Fee:</div>

          <div title="amountdeducted">- INR {props.tnsfee}</div>
        </div>
        <div className="dedx blue">
          <div title="reasonofdeduction"></div>

          <div title="amountdeducted">INR {props.amztotal}</div>
        </div>
      </div>
    </div>
  );
};

export default Inr;
