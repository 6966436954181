import "./styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Navbar, Container, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalculator } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import Inr from "./components/inr";
import Usd from "./components/usd";
export default function App() {
  let [change, setChange] = useState(true);
  let [platformupi, setplatformupi] = useState("5%");

  let [platformupicalc, setplatformupicalc] = useState(0);
  let [upigst, setupigst] = useState(0);
  let [upitotal, setupitotal] = useState(0);

  let [platformpaytm, setplatformpaytm] = useState("10%");
  let [platformpaytmcalc, setplatformpaytmcalc] = useState(0);
  let [paytmgst, setpaytmgst] = useState(0);
  let [paytmtotal, setpaytmtotal] = useState(0);

  let [platformamazon, setplatformamazon] = useState("6%");
  let [platformamazoncalc, setplatformamazoncalc] = useState(0);
  let [amazongst, setamazongst] = useState(0);
  let [amztotal, setamazontotal] = useState(0);

  let [base, setbase] = useState(0);
  var [tnsfee, settnsfee] = useState(0);
  let [amountinput, setamountinput] = useState();

  let gst = "18%";

  function calc() {
    var amount = amountinput;
    platformupicalc = (amount * 0.05).toFixed(2);
    platformamazoncalc = (amount * 0.08).toFixed(2);
    platformpaytmcalc = (amount * 0.1).toFixed(2);
    if (change == false) {
      amount = amount * 65;
      setplatformupi("7%");
      setplatformpaytm("12%");
      setplatformamazon("8%");
      platformupicalc = (amount * 0.07).toFixed(2);
      platformamazoncalc = (amount * 0.08).toFixed(2);
      platformpaytmcalc = (amount * 0.12).toFixed(2);
    } else {
      setplatformupi("5%");
      setplatformpaytm("10%");
      setplatformamazon("6%");
      platformupicalc = (amount * 0.05).toFixed(2);
      platformamazoncalc = (amount * 0.06).toFixed(2);
      platformpaytmcalc = (amount * 0.1).toFixed(2);
    }

    setbase(amount);
    var tnsfeex = 0;
    if (amount >= 1000) {
      tnsfeex = 1;
      settnsfee(1);
    } else {
      settnsfee(0);
    }
    //upi

    upigst = (platformupicalc * 0.18).toFixed(2);
    upitotal = (amount - platformupicalc - upigst - tnsfeex).toFixed(2);
    setplatformupicalc(platformupicalc);
    setupitotal(upitotal);
    setupigst(upigst);

    amazongst = (platformamazoncalc * 0.18).toFixed(2);
    amztotal = (amount - platformamazoncalc - amazongst - tnsfeex).toFixed(2);
    setplatformamazoncalc(platformamazoncalc);
    setamazongst(amazongst);
    setamazontotal(amztotal);

    paytmgst = (platformpaytmcalc * 0.18).toFixed(2);
    paytmtotal = (amount - platformpaytmcalc - paytmgst - tnsfeex).toFixed(2);
    setplatformpaytmcalc(platformpaytmcalc);
    setpaytmgst(paytmgst);
    setpaytmtotal(paytmtotal);
    //console.log("ASdasd");
  }

  useEffect(() => {
    calc();
    // console.log("changing");
  }, [change]);

  return (
    <div className="App">
      <Navbar bg="light">
        <Container>
          <Navbar.Brand href="#home" fixed="top">
            <img
              src="https://anonpe.com/img/2022logo.svg"
              width="150"
              height="60"
              className="d-inline-block align-top"
              alt="Anonpe"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="https://anonpe.com">Home</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div className="header">
        <h1>Pricing Calculator</h1>
        <div className="currencies">
          <button
            className={`currencybtns ${change ? "activex" : "active"}`}
            onClick={() => {
              setChange(true);
              calc();
            }}
          >
            INR
          </button>

          <button
            onClick={() => {
              setChange(false);
              calc();
            }}
            className={`currencybtns ${change ? "" : "activex"}`}
          >
            USD
          </button>
        </div>
      </div>

      <div className="hoax">
        See the math behind the price for our service. Our platform earns only
        if our merchant earns. We charge only on credits withdrawal else
        everything is <span className="notice">FREE</span>.
      </div>

      <div className="form">
        <input
          type="text"
          value={amountinput}
          onChange={(e) => setamountinput(e.target.value)}
          placeholder={
            change
              ? "Enter the withdrawal amount (INR)"
              : "Enter the withdrawal amount (USD)"
          }
          onKeyUp={() => {
            calc();
          }}
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
        />
      </div>
      {change ? "" : <Usd />}
      <Inr
        base={base}
        platformupi={platformupi}
        platformpaytm={platformpaytm}
        platformamazon={platformamazon}
        platformupicalc={platformupicalc}
        platformamazoncalc={platformamazoncalc}
        platformpaytmcalc={platformpaytmcalc}
        gst={gst}
        upigst={upigst}
        paytmgst={paytmgst}
        amazongst={amazongst}
        tnsfee={tnsfee}
        upitotal={upitotal}
        paytmtotal={paytmtotal}
        amztotal={amztotal}
      />
    </div>
  );
}
