import Inr from "./inr";
const Usd = (props) => {
  return (
    <div>
      <div className="usdconversion">
        <div className="title">
          <strong>Conversion rate</strong> = INR 65 / American US Dollars
        </div>
      </div>
    </div>
  );
};
export default Usd;
